<template>
  <SidebarCaption>General</SidebarCaption>
  <BaseSidebar id="IntegrationsSidebar" :navigation="navigation" />
</template>

<script setup lang="ts">
import { BaseSidebar, SidebarCaption } from '@apiida/vue-components';

const navigation = [
  {
    name: 'Overview',
    route: 'ApiProductOverview',
    icon: 'Home',
  },
  {
    name: 'Assigned APIs',
    route: 'ApiProductAssignedApis',
    icon: 'API',
  },
  {
    name: 'Visibility',
    route: 'ApiProductVisibility',
    icon: 'Show',
  },
  {
    name: 'Deployments',
    route: 'ApiProductDeployments',
    icon: 'Publish',
  },
  {
    name: 'Plans',
    route: 'ApiProductPlans',
    icon: 'Paste',
  },
  {
    name: 'Subscriptions',
    route: 'ApiProductSubscriptions',
    icon: 'Run',
  },
  {
    name: 'Documentation',
    route: 'ApiProductDocRootPage',
    icon: 'Library',
  },
  {
    name: 'Terms of Use',
    route: 'ApiProductTermsOfUse',
    icon: 'Document',
  },
  {
    name: 'Settings',
    route: 'ApiProductSettings',
    icon: 'Settings',
  },
];
</script>
