import {
  ApiClientHelper,
  Job,
  Organization,
  Pageable,
  SimplePage,
  TableHeaderPageable,
} from '@apiida/controlplane-api-helper';
import Environment from '../../types/environments/Environment';
import AgentToken from '../../types/agent/AgentToken';
import ApiClient from '../ApiClient';
import ApiDeployment from '../../types/apis/ApiDeployment';
import environmentStore from '../../stores/EnvironmentStore';
import PaginationService from '../PaginationService';
import { Platform } from '@/types/enums/Platform';
import ApplicationDeployment from '../../types/applications/ApplicationDeployment';
import PlanDeployment from '../../types/configuration/PlanDeployment';
import SubscriptionDeployment from '../../types/applications/SubscriptionDeployment';

class EnvironmentService extends PaginationService<Environment> {
  constructor() {
    super('/environments', 'Environment');
  }

  getStore(): any {
    return environmentStore();
  }

  storeFill(entitys: Environment[]): void {
    this.getStore().environments = entitys;
  }

  storeGetAllEntitys(): Environment[] {
    return this.getStore().environments;
  }

  async create(data: Environment, silent?: boolean): Promise<Environment | null> {
    return super.create(data, silent);
  }

  async requestAgentToken(environmentId: number): Promise<AgentToken> {
    const response = await ApiClient.put<AgentToken>(`${this.url}/${environmentId}/agent`);
    return response?.data as AgentToken;
  }

  async getAllPinnedEnvironments<T = Environment>(silent: boolean = false): Promise<T[]> {
    const response = await ApiClient.get<SimplePage<T>>(
      `${this.url}?pinned=true&size=9999&sort=displayName`,
      silent,
    );
    return response?.data?.content ?? [];
  }

  async refreshAllGatewayInfo(): Promise<boolean> {
    const response = await ApiClient.post<Job>(`${this.url}/refreshAllGatewayInfo`);

    return ApiClientHelper.isSuccess(response);
  }

  async refreshGatewayInfo(envId: number): Promise<boolean> {
    const response = await ApiClient.post<Job>(`${this.url}/${envId}/agent/refreshGatewayInfo`);

    return ApiClientHelper.isSuccess(response);
  }

  // region deployments

  async getApiDeployments(
    pageable: Pageable,
    environment: number,
    header: Array<TableHeaderPageable | string> = [],
  ): Promise<SimplePage<ApiDeployment>> {
    return this.getAllPagination(
      pageable,
      header,
      [],
      false,
      `/environments/${environment}/deployments/apis`,
    ) as unknown as SimplePage<ApiDeployment>;
  }

  async getApplicationDeployments(
    pageable: Pageable,
    environment: number,
    header: Array<TableHeaderPageable | string> = [],
  ): Promise<SimplePage<ApplicationDeployment>> {
    return this.getAllPagination(
      pageable,
      header,
      [],
      false,
      `/environments/${environment}/deployments/applications`,
    ) as unknown as SimplePage<ApplicationDeployment>;
  }

  async getPlanDeployments(
    pageable: Pageable,
    environment: number,
    header: Array<TableHeaderPageable | string> = [],
  ): Promise<SimplePage<PlanDeployment>> {
    return this.getAllPagination(
      pageable,
      header,
      [],
      false,
      `/environments/${environment}/deployments/plans`,
    ) as unknown as SimplePage<PlanDeployment>;
  }

  async getSubscriptionDeployments(
    pageable: Pageable,
    environment: number,
    header: Array<TableHeaderPageable | string> = [],
  ): Promise<SimplePage<SubscriptionDeployment>> {
    return this.getAllPagination(
      pageable,
      header,
      [],
      false,
      `/environments/${environment}/deployments/subscriptions`,
    ) as unknown as SimplePage<SubscriptionDeployment>;
  }

  // endregion

  async canCreate(platform: Platform): Promise<boolean> {
    const response = await ApiClient.post<{ canCreateActive: boolean }>(
      `${this.url}/canCreate?type=${platform}`,
    );

    return response?.data.canCreateActive ?? false;
  }

  // access management

  async addSubscribeOrganization(environmentId: number, organizationId: number): Promise<Boolean> {
    const response = await ApiClient.put(
      `${this.url}/${environmentId}/subscribeOrganizations/${organizationId}`,
    );
    return ApiClientHelper.isSuccess(response);
  }

  async removeSubscribeOrganization(
    environmentId: number,
    organizationId: number,
  ): Promise<Boolean> {
    const response = await ApiClient.delete(
      `${this.url}/${environmentId}/subscribeOrganizations/${organizationId}`,
    );
    return ApiClientHelper.isSuccess(response);
  }

  async getAllSubscribeOrganizations(
    pageable: Pageable,
    environmentId: number,
    header: Array<TableHeaderPageable | string> = [],
  ): Promise<SimplePage<Organization>> {
    return this.getAllPagination(
      pageable,
      header,
      [],
      false,
      `/environments/${environmentId}/subscribeOrganizations`,
    ) as unknown as SimplePage<Organization>;
  }

  async getAllAvailableSubscribeOrganizations(
    pageable: Pageable,
    environmentId: number,
    header: Array<TableHeaderPageable | string> = [],
  ): Promise<SimplePage<Organization>> {
    return this.getAllPagination(
      pageable,
      header,
      [],
      false,
      `/environments/${environmentId}/subscribeOrganizations/available`,
    ) as unknown as SimplePage<Organization>;
  }

  async addPublishOrganization(environmentId: number, organizationId: number): Promise<Boolean> {
    const response = await ApiClient.put(
      `${this.url}/${environmentId}/publishOrganizations/${organizationId}`,
    );
    return ApiClientHelper.isSuccess(response);
  }

  async removePublishOrganization(environmentId: number, organizationId: number): Promise<Boolean> {
    const response = await ApiClient.delete(
      `${this.url}/${environmentId}/publishOrganizations/${organizationId}`,
    );
    return ApiClientHelper.isSuccess(response);
  }

  async getAllPublishOrganizations(
    pageable: Pageable,
    environmentId: number,
    header: Array<TableHeaderPageable | string> = [],
  ): Promise<SimplePage<Organization>> {
    return this.getAllPagination(
      pageable,
      header,
      [],
      false,
      `/environments/${environmentId}/publishOrganizations`,
    ) as unknown as SimplePage<Organization>;
  }

  async getAllAvailablePublishOrganizations(
    pageable: Pageable,
    environmentId: number,
    header: Array<TableHeaderPageable | string> = [],
  ): Promise<SimplePage<Organization>> {
    return this.getAllPagination(
      pageable,
      header,
      [],
      false,
      `/environments/${environmentId}/publishOrganizations/available`,
    ) as unknown as SimplePage<Organization>;
  }
}

export default new EnvironmentService();
