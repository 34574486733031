<template>
  <SidebarCaption>General</SidebarCaption>
  <BaseSidebar :navigation="items" />
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { BaseSidebar, SidebarCaption } from '@apiida/vue-components';
import CustomApplicationFieldsService from '../../services/configuration/CustomApplicationFieldsService';

const baseNavigation = [
  {
    name: 'Overview',
    route: 'ApplicationOverview',
    icon: 'Home',
  },
  {
    name: 'Subscriptions',
    route: 'ApplicationSubscriptions',
    icon: 'Run',
  },
  {
    name: 'Analytics',
    route: 'ApplicationAnalytics',
    icon: 'Line graph',
  },
  {
    name: 'Deployments',
    route: 'ApplicationDeployments',
    icon: 'Publish',
  },
];

const settings = {
  name: 'Settings',
  route: 'ApplicationSettings',
  icon: 'Settings',
};

const customFields = {
  name: 'Custom Fields',
  route: 'ApplicationCustomFields',
  icon: 'Table',
};

const items = ref<
  {
    name: string;
    route: string;
    icon: string;
  }[]
>([]);
onMounted(async () => {
  items.value.push(...baseNavigation);

  const tmpCustomFields = await CustomApplicationFieldsService.getAll();
  if (tmpCustomFields.length > 0) {
    items.value.push({ ...customFields });
  }

  items.value.push({ ...settings });
});
</script>
