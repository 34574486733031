import { defineStore } from 'pinia';
import ApiProduct from '../types/apiProducts/ApiProduct';
import DocPage from '../types/documentation/DocPage';

const apiProductStore = defineStore({
  id: 'apiProduct',
  state: () => ({
    apiProducts: [] as ApiProduct[],
    images: [] as { productId: number; image: string }[],
    docCurrentParent: {} as DocPage,
  }),
  getters: {
    getApiProducts(): ApiProduct[] {
      return this.apiProducts;
    },
    getDocParent(): DocPage {
      return this.docCurrentParent;
    },
  },
  actions: {
    setDocParent(docCurrentParent: DocPage) {
      this.docCurrentParent = docCurrentParent;
    },
    getApiProductByDI(id: number): ApiProduct | undefined {
      return this.apiProducts.find(
        (productInArray: ApiProduct) => Number(productInArray.id) === Number(id),
      );
    },
    getImage(productId: number): string | undefined {
      const apiImage: { productId: number; image: string } | undefined = this.images.find(
        (aI: { productId: number; image: string }) => Number(aI.productId) === Number(productId),
      );
      if (apiImage) {
        return apiImage.image;
      }
      return undefined;
    },
    storeOrUpdateImage(productId: number, image: string): void {
      let storeIt = true;
      this.images.map((aI: { productId: number; image: string }) => {
        if (Number(aI.productId) === Number(productId)) {
          aI.image = image;
          storeIt = false;
        }
        return aI;
      });
      if (storeIt) {
        this.images.push({ productId, image });
      }
    },
    deleteImage(productId: number): void {
      const indexOfObject = this.images.findIndex(
        (aI: { productId: number; image: string }) => Number(aI.productId) === Number(productId),
      );
      this.images.splice(indexOfObject, 1);
    },
  },
});

export default apiProductStore;
