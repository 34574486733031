<template>
  <SidebarCaption>General</SidebarCaption>
  <BaseSidebar id="IntegrationsSidebar" :navigation="tmpNavigation" />
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { BaseSidebar, SidebarCaption } from '@apiida/vue-components';
import RoleGuards from '../../routers/guards/RoleGuards';

const navigation = [{ name: 'Overview', route: 'EnvironmentOverview', icon: 'Home' }];

const tmpNavigation = ref<{ name: string; route: string; icon: string }[]>([]);

onMounted(async () => {
  tmpNavigation.value = [...navigation];
  if (RoleGuards.isGlobalAdmin()) {
    tmpNavigation.value.push({
      name: 'Deployments',
      route: 'EnvironmentDeployments',
      icon: 'Publish',
    });
    tmpNavigation.value.push({
      name: 'Access Management',
      route: 'EnvironmentAccessManagementSubscribe',
      icon: 'Key',
    });
    tmpNavigation.value.push({
      name: 'Settings',
      route: 'EnvironmentSettings',
      icon: 'Settings',
    });
  }
});
</script>
