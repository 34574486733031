<template>
  <ex-breadcrumb>
    <div class="flex">
      <ex-breadcrumb-item
        v-for="item in [...getGeneralBreadcrumbs(), ...getDocPageBreadcrumbs()]"
        :key="item.link"
      >
        <router-link
          class="flex max-w-40 lg:max-w-xs 2.5xl:max-w-lg 4xl:max-w-4xl line-clamp-1"
          :to="item.link"
          aria-current="page"
        >
          {{ item.text }}
        </router-link>
      </ex-breadcrumb-item>
    </div>
  </ex-breadcrumb>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import environmentStore from '../../stores/EnvironmentStore';
import apiStore from '../../stores/ApiStore';
import apiPStore from '../../stores/ApiProductStore';
import applicationStore from '../../stores/ApplicationStore';
import userStore from '../../stores/UserStore';
import planStore from '../../stores/PlanStore';
import organizationStore from '../../stores/OrganizationStore';
import gitCredentialStore from '../../stores/GitCredentialStore';
import ruleSetStore from '../../stores/RuleSetStore';
import devPortalStore from '../../stores/DevPortalStore';
import Breadcrumb from '../../types/app/Breadcrumb';
import termOfUseStore from '../../stores/TermOfUseStore';
import webhookStore from '../../stores/WebhookStore';
import useDocPageStore from '@/stores/DocPageStore';
import DocPage from '@/types/documentation/DocPage';

const route = useRoute();
const envStore = environmentStore();
const aStore = apiStore();
const apiProductStore = apiPStore();
const applicatioStore = applicationStore();
const useStore = userStore();
const plaStore = planStore();
const orgStore = organizationStore();
const gitStore = gitCredentialStore();
const ruleStore = ruleSetStore();
const devPoStore = devPortalStore();
const touStore = termOfUseStore();
const myWebhookStore = webhookStore();
let currentRoute = '';

function getTo(path: string): string {
  currentRoute += `/${path}`;
  return currentRoute;
}

/**
 * This function loads the name of the entity from the current path, alternatively from “meta”.
 * The breadcrumb name from meta in the router must match the switch.
 * @param idx
 * @param path
 */
function loadNameFromEntity(idx: number, path: string): string {
  if (idx - 1 >= 0 && route.matched[idx - 1] && route.matched[idx - 1].meta.isEntity) {
    const name = route.matched[idx - 1].name ?? route.matched[idx - 1].meta.BreadcrumbName;

    switch (name) {
      case 'Environments': {
        const env = envStore.getEnvironmentById(parseInt(path, 10));
        return env ? env.displayName : 'Not loaded yet';
      }
      case 'APIs': {
        const api = aStore.getAPIByID(parseInt(path, 10));
        return api ? api.displayName : 'Not loaded yet';
      }
      case 'API Products': {
        const apiProduct = apiProductStore.getApiProductByDI(parseInt(path, 10));
        return apiProduct ? apiProduct.displayName : 'Not loaded yet';
      }
      case 'Applications': {
        const application = applicatioStore.getApplicationByDI(parseInt(path, 10));
        return application ? application.displayName : 'Not loaded yet';
      }
      case 'ConfigurationUsers': {
        const user = useStore.getUserByDI(parseInt(path, 10));
        return user ? user.displayName : 'Not loaded yet';
      }
      case 'ConfigurationPlans': {
        const plan = plaStore.getPlanByDI(parseInt(path, 10));
        return plan ? plan.displayName : 'Not loaded yet';
      }
      case 'ConfigurationOrganizations': {
        const organization = orgStore.getOrganizationByDI(parseInt(path, 10));
        return organization ? organization.displayName : 'Not loaded yet';
      }
      case 'ConfigurationGitCredentials': {
        const gitCredentials = gitStore.getGitCredentialByDI(parseInt(path, 10));
        return gitCredentials ? gitCredentials.displayName : 'Not loaded yet';
      }
      case 'ConfigurationRuleSets': {
        const ruleSet = ruleStore.getRuleSetByDI(parseInt(path, 10));
        return ruleSet ? ruleSet.name : 'Not loaded yet';
      }
      case 'ConfigurationDevPortal': {
        const devPortal = devPoStore.getDevPortalByDI(parseInt(path, 10));
        return devPortal ? devPortal.name : 'Not loaded yet';
      }
      case 'ConfigurationTermsOfUse': {
        const tou = touStore.getTermOfUseByDI(parseInt(path, 10));
        return tou ? tou.name : 'Not loaded yet';
      }
      case 'ConfigurationWebhooks': {
        const webhook = myWebhookStore.getWebhockByDI(parseInt(path, 10));
        return webhook ? webhook.displayName : 'Not loaded yet';
      }
      default: {
        console.log(route.matched[idx - 1].meta, `name ${name} not found`);
        return 'Case in BreadcrumbList.vue missing';
      }
    }
  }

  // If the child of a route has a name as the route in the breadcrumbs.
  if (route.matched[idx]) {
    if (route.matched[idx].children && route.matched[idx].children.length > 0) {
      const matchedChild = route.matched[idx].children.find(
        (child) => path === child.path && child.meta?.BreadcrumbName,
      );
      if (matchedChild) {
        return String(matchedChild.meta?.BreadcrumbName);
      }
    }
  }

  if (route.matched[idx] && route.matched[idx].meta.BreadcrumbName) {
    return String(route.matched[idx].meta.BreadcrumbName);
  }
  return path.charAt(0).toUpperCase() + path.slice(1);
}

function docHandler(arr: string[]): string[] {
  const index = arr.indexOf('docContent');

  if (index !== -1) {
    arr.splice(index, 2);
  }

  return arr;
}

/**
 * Returns all “parents” available in the routers and converts them into breadcrumbs.
 */
function getGeneralBreadcrumbs() {
  currentRoute = '';
  let pathArray = route.path.split('/');

  pathArray.shift();
  pathArray = docHandler(pathArray);

  return pathArray.reduce((breadcrumbArray: Array<Breadcrumb>, path: string, idx: number) => {
    breadcrumbArray.push({
      link: getTo(path),
      text: loadNameFromEntity(idx, decodeURI(path)),
    } as Breadcrumb);
    return breadcrumbArray;
  }, []);
}

const docPageStore = useDocPageStore();

/**
 * Returns the Breadcrumbs from DocPageStore
 */
function getDocPageBreadcrumbs(): Breadcrumb[] {
  const [, entityType, entityId, category, subcategory, docPageId] = route.path.split('/');

  // check if we are inside the docs
  if (category !== 'documentation' || subcategory !== 'docContent' || !docPageId) {
    return [];
  }

  return docPageStore.getParentPath(+docPageId).map((docPage: DocPage) => ({
    link: `/${entityType}/${entityId}/documentation/docContent/${docPage.id}`,
    text: docPage.name,
  }));
}
</script>
