import { defineStore } from 'pinia';
import DocPage from '@/types/documentation/DocPage';
import { DocEntityType } from '@/types/enums/DocEntityType';

export const useDocPageStore = defineStore('docPage', {
  state: () => ({
    docPages: [] as DocPage[],
    docEntityType: DocEntityType.API as DocEntityType,
    docEntityId: 0,
  }),

  actions: {
    /**
     * Updates the store with multiple DocPages
     * @param pages Array of DocPages to store
     */
    setDocPages(pages: DocPage[]) {
      this.docPages = pages;
    },
    setDocEntityType(docEntityType: DocEntityType) {
      this.docEntityType = docEntityType;
    },
    setDocEntityId(id: number) {
      this.docEntityId = id;
    },
  },

  getters: {
    /**
     * Returns all stored DocPages
     */
    getAllPages(): DocPage[] {
      return this.docPages;
    },

    /**
     * Returns a page by its ID
     */
    getPageById:
      (state) =>
      (id: number): DocPage | undefined => {
        return state.docPages.find((page) => page.id === id);
      },

    /**
     * Returns the parent path for a given page ID
     */
    getParentPath:
      (state) =>
      (pageId: number): DocPage[] => {
        const path: DocPage[] = [];

        const findParentPath = (pages: DocPage[], targetId: number): boolean => {
          for (const page of pages) {
            if (page.id === targetId) {
              path.unshift(page); // Add the target page to the path
              return true;
            }

            if (page.children && page.children.length > 0) {
              if (findParentPath(page.children, targetId)) {
                path.unshift(page);
                return true;
              }
            }
          }
          return false;
        };

        findParentPath(state.docPages, pageId);
        return path;
      },
  },
});
export default useDocPageStore;
