<template>
  <MultiColumnLayout :hide-bar-by-small-resolution="hideBarBySmallResolution">
    <template #sidebar>
      <InitialsAvatar
        v-if="environment"
        :title="environment.displayName"
        :initials="environment.symbol"
        :color="environment.color"
        title-classes="text-sm text-gray-900 font-normal w-40"
      >
        <template #subtitle>
          <div class="mt-1">
            <AgentStatusLabel
              v-if="environment.id !== null && environment.agent !== undefined"
              :agent-status="environment.agent.status"
              :gateway-info="environment.agent.gateway"
            />
          </div>
        </template>
      </InitialsAvatar>

      <DeploymentSidebarBanner
        v-if="environment"
        :status="environment.deploymentStatus"
        :to="{
          name: 'EnvironmentOverview',
          params: {
            environmentId: environment.id,
          },
        }"
      />

      <InActiveSidebarBanner
        v-if="environment"
        entity="Environment"
        :status="environment.status"
        :to="{
          name: 'EnvironmentSettings',
          params: {
            environmentId: environment.id,
          },
        }"
      />

      <EnvironmentSidebar />
    </template>

    <slot />
  </MultiColumnLayout>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { InitialsAvatar } from '@apiida/vue-components';
import EnvironmentSidebar from './EnvironmentSidebar.vue';
import AgentStatusLabel from '../agent/AgentStatusLabel.vue';
import DeploymentSidebarBanner from '../common/DeploymentSidebarBanner.vue';
import environmentStore from '../../stores/EnvironmentStore';
import InActiveSidebarBanner from '../common/InActiveSidebarBanner.vue';
import EnvironmentService from '@/services/environments/EnvironmentService';

const props = defineProps({
  environmentId: {
    type: Number,
    required: true,
  },
  hideBarBySmallResolution: {
    type: Boolean,
    default: true,
  },
});

const envStore = environmentStore();
const environment = computed(() => envStore.getEnvironmentById(props.environmentId));

onMounted(async () => {
  if (!envStore.getEnvironmentById(props.environmentId) !== undefined) {
    await EnvironmentService.get(props.environmentId);
  }
});
</script>
