<template>
  <div
    v-if="
      (boomiApiGatewayLinkIsPresent && mStore.SaaSInfo.linkedWithBoomi) ||
      (boomiCloudApiManagementLinkIsPresent && mStore.SaaSInfo.linkedWithCAM)
    "
    data-test-id="apim_services_button"
    class="menu-link flex items-center p-2 rounded-lg hover:bg-primary hover:text-secondary cursor-pointer"
    @click="showApimServicesMenu = !showApimServicesMenu"
  >
    <ex-icon
      icon="api-management"
      label="API Management Services"
      class="h-6 w-6"
      aria-hidden="true"
      style="display: inline-block; font-size: var(--exo-font-size-3x-large)"
    />
    <span v-if="menuBig" class="pl-2 text-nowrap">APIM Services</span>
    <ex-menu
      v-if="showApimServicesMenu"
      :class="[
        'absolute bottom-[8.15rem] z-10 text-nowrap',
        menuBig ? 'left-[12rem]' : 'left-[2.5rem]',
      ]"
      variant="action"
      customWidth="220"
    >
      <ex-menu-item
        v-if="boomiApiGatewayLinkIsPresent && mStore.SaaSInfo.linkedWithBoomi"
        key="boomiPlatform"
        icon="Open in new window"
        @click="linkBoomiPlatform"
      >
        <p>API Gateway</p>
      </ex-menu-item>
      <ex-menu-item
        v-if="boomiCloudApiManagementLinkIsPresent && mStore.SaaSInfo.linkedWithCAM"
        key="CAM"
        icon="Open in new window"
        @click="linkBoomiCAM"
      >
        <p>Cloud API Management</p>
      </ex-menu-item>
    </ex-menu>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';
import mainStore from '../../stores/MainStore';
import config from '@/config';
import openNewTap from '@/helper/OpenLinkHelper';

defineProps({
  menuBig: {
    type: Boolean,
    required: true,
  },
});

// backend feature flag - mStore.SaaSInfo.linkedWithCAM
const mStore = mainStore();

const showApimServicesMenu = ref(false);

function linkBoomiPlatform() {
  showApimServicesMenu.value = false;
  openNewTap(`${config.apimServicesBoomiApiGateway}${mStore.SaaSInfo.tenantId}`);
}

function linkBoomiCAM() {
  showApimServicesMenu.value = false;
  openNewTap(`${config.apimServicesBoomiCloudApiManagement}${mStore.SaaSInfo.tenantId}`);
}

// "-" is set via an environment variable to hide the link.
const boomiApiGatewayLinkIsPresent = ref(config.apimServicesBoomiApiGateway.length > 3);
const boomiCloudApiManagementLinkIsPresent = ref(
  config.apimServicesBoomiCloudApiManagement.length > 3,
);

// Handler for global click
const handleGlobalClick = (event: MouseEvent) => {
  const APIMServicesButtonSections = document.querySelectorAll(
    '[data-test-id="apim_services_button"]',
  );

  if (APIMServicesButtonSections.length === 0) return;

  // Check if the click was inside any of the APIM services sections
  const wasClickedInsideAnySection = Array.from(APIMServicesButtonSections).some((section) =>
    section.contains(event.target as Node),
  );

  // Only close the menu if the click was outside all sections
  if (!wasClickedInsideAnySection) {
    showApimServicesMenu.value = false;
  }
};

// Add and remove event listeners
onMounted(() => {
  document.addEventListener('click', handleGlobalClick);
});

onUnmounted(() => {
  document.removeEventListener('click', handleGlobalClick);
});
</script>
