<template>
  <SidebarCaption>General</SidebarCaption>
  <BaseSidebar id="IntegrationsSidebar" :navigation="navigation" />
</template>

<script setup lang="ts">
import { BaseSidebar, SidebarCaption } from '@apiida/vue-components';

const navigation = [
  {
    name: 'Overview',
    route: 'ApiOverview',
    icon: 'Home',
  },
  {
    name: 'API Products',
    route: 'ApiApiProduct',
    icon: 'Types',
  },
  {
    name: 'Versions',
    route: 'ApiVersions',
    icon: 'Component',
  },
  {
    name: 'Analytics',
    route: 'ApiAnalytics',
    icon: 'Line graph',
  },
  {
    name: 'Deployments',
    route: 'ApiDeployments',
    icon: 'Publish',
  },
  {
    name: 'Documentation',
    route: 'ApiDocRootPage',
    icon: 'Document',
  },
  {
    name: 'Settings',
    route: 'ApiSettings',
    icon: 'Settings',
  },
];
</script>
